import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { environment } from 'src/environments/environment';
import { FetchService } from './fetch.service';

@Injectable({
    providedIn: 'root'
})
export class StatsService {

    constructor(public utils: UtilsService, private localFetch: FetchService) {}

    getStats(obj) {
      return new Promise((resolve, reject) => {
        this.localFetch.postFetch(environment.baseUrl + '/stats', obj).then((d: any) => {
            if(d) {
                resolve(d);
            } else {
                reject(false);
            }
        }, e => {
            reject(false)
        });
      });
    }

}
